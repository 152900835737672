/*.pageContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    text-align: center;*/
/*    width: 100%;*/
/*    justify-content: center;*/
/*    !* any other styles you want *!*/
/*}*/
/*.filterContainer {*/
/*    display: flex;*/
/*    gap: 20px; !* space between filters *!*/
/*    flex-wrap: wrap; !* so they wrap on small screens *!*/
/*    justify-content: center;*/
/*    margin: 20px 0; !* spacing above/below *!*/
/*}*/
/*!* PlayerComparison.module.css *!*/

/*!* A container for the entire page *!*/
/*.pageContainer {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;       !* center horizontally *!*/
/*  width: 100%;*/
/*  text-align: center;        !* center text as well *!*/
/*  justify-content: center;   !* center content horizontally in flex context *!*/
/*  margin: 0 auto;            !* ensure it's centered if it has a max-width *!*/
/*  padding: 20px;             !* optional padding *!*/
/*}*/

/*!* The page's main title *!*/
/*.title {*/
/*  font-size: 2rem;           !* or use clamp for responsiveness *!*/
/*  color: #333;*/
/*  margin-top: 20px;*/
/*  margin-bottom: 20px;*/
/*}*/

/*!* A container to hold both filter sections side by side *!*/
/*.filterContainer {*/
/*  display: flex;*/
/*  gap: 20px;                 !* space between them *!*/
/*  flex-wrap: wrap;           !* so it wraps on small screens *!*/
/*  justify-content: center;   !* center them horizontally *!*/
/*  margin-bottom: 30px;       !* space below the filters *!*/
/*}*/

/*!* A small style for the "Comparing Player X to Player Y" section *!*/
/*.comparisonText {*/
/*  font-size: 1.2rem;*/
/*  color: #555;*/
/*  margin-top: 20px;*/
/*}*/


/* Center the entire page, apply white background, and default text color (#0097b3) */
.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff; /* Secondary color (white) */
  color: #0097b3;           /* Primary color as default text color */
  padding: 20px;
  box-sizing: border-box;
}

/* A large, centered title for the page */
.title {
  text-align: center;
  color: #0097b3;  /* Primary color */
  font-size: clamp(24px, 4vw, 48px);
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.subtitle {
  text-align: center;
  color: #666666; /* Softer, neutral tone */
  font-size: clamp(12px, 2vw, 24px); /* Responsive, slightly smaller than title */
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 500; /* Medium weight for contrast */
  font-style: italic; /* Optional: adds distinction */
}

/* Container for the two filter components side-by-side (wrap on smaller screens) */
.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;                   /* Space between filters */
  justify-content: center;     /* Center horizontally */
  align-items: flex-start;     /* Align them at the top */
  width: 100%;
  margin-bottom: 40px;
}

/* Comparison text after both players are chosen */
.comparisonText {
  text-align: center;
  font-size: 1.25rem;
  color: #a0732c; /* Tertiary color for emphasis */
  margin-top: 30px;
}

/*
  If your final player dropdown is a native <select>, style it here.
  Increase width and line-height so you have room for images in <option>.
*/
.playerSelect {
  width: 350px;          /* Wider select box */
  min-height: 50px;      /* Make the control itself taller */
  line-height: 2rem;     /* Increase line spacing for the displayed value */
  font-size: 1rem;       /* Adjust font size to your liking */
  padding: 5px 10px;
  background-color: #ffffff;
  border: 2px solid #0097b3;
  border-radius: 5px;
  color: #0097b3;
  cursor: pointer;
  box-sizing: border-box;
}

/* For the <option> elements: increase height/line spacing so images can fit */
.playerSelect option {
  padding: 0.5rem;
  line-height: 2rem;   /* Height per option (2x typical) */
}

/* Example styling for a label or heading above the selects, if you want consistent looks */
.filterLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
  color: #0097b3;
}

/* If you ever need a small "note" or "hint" text under the select */
.filterHint {
  font-size: 0.85rem;
  color: #a0732c; /* Tertiary color */
  margin-top: 4px;
}
.compareTo {
  font-weight: 800; /* or 700, 900, etc. */
  font-size: 1.5rem; /* optional, to make it stand out */
  margin-bottom: 8px;
  color: #a0732c; /* if you want a specific color for these labels */
  text-transform: uppercase; /* optional, if you want "COMPARE" / "TO" in uppercase */
}
/*.compareButtonContainer {*/
/*  text-align: center;*/
/*  margin: 20px 0;*/
/*}*/

.compareButton {
  background-color: #0097b3;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.compareButton:hover {
  background-color: #007a8a;
}

/* A container to place both player "cards" side by side */
.comparisonSection {
  display: flex;
  gap: 20px;
  justify-content: center; /* center them horizontally */
  align-items: flex-start;
  margin-top: 30px;
}

/* Each player's stats "card" */
.playerCard {
  border: 1px solid #ccc;
  padding: 1rem;
  width: 250px;
  background-color: #f9f9f9;
  border-radius: 5px;
}
/*.compareButtonContainer {*/
/*  display: flex;*/
/*  gap: 10px;*/
/*  justify-content: center;*/
/*  margin-top: 20px;*/
/*  align-items: center;*/
/*}*/

.timeframeSelect {
  font-size: 1rem;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.compareButton {
  background-color: #0097b3;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.compareButton:disabled {
  background-color: #999;
  cursor: not-allowed;
}
/* In your .module.css */
.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #0097b3;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingBarContainer {
  margin-bottom: 12px;
}

.loadingBarTitle {
  font-weight: bold;
  margin: 6px 0;
}

.loadingBar {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 15px;
  overflow: hidden;
}

.teamLeftXgPercentage,
.teamRightXgPercentage {
  position: absolute;
  top: 0;
  bottom: 0;
  transition: width 1s ease;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Left bar anchored to left side */
.teamLeftXgPercentage {
  left: 0;
  background-color: #0097b3; /* or any color you like for Player 1 */
  border-right: 1px solid white;
}

/* Right bar anchored to right side */
.teamRightXgPercentage {
  right: 0;
  background-color: #a0732c; /* or color for Player 2 */
}

/* The text inside each bar */
.xgText {
  pointer-events: none;
}
.statBarsContainer {
  width: 40%; /* Take 80% of the page width */
  margin: 0 auto; /* Center the container */
  padding: 20px;
}

.loadingBarContainer {
  width: 100%;
  display: flex;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  height: 30px; /* Make bars taller */
  margin-bottom: 15px;
}

.loadingBar {
  width: 100%;
  display: flex;
  position: relative;
}

/*.teamLeftXgPercentage {*/
/*  background-color: #007bff; !* Blue for player 1 *!*/
/*  height: 100%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: flex-end;*/
/*  padding-right: 10px;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/

/*.teamRightXgPercentage {*/
/*  background-color: #ff4b5c; !* Red for player 2 *!*/
/*  height: 100%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  padding-left: 10px;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/
/*.loadingBar {*/
/*  display: flex;*/
/*  position: relative;*/
/*  height: 40px; !* Adjust for larger bars *!*/
/*  background-color: #e0e0e0; !* Base bar background *!*/
/*}*/

/*.teamLeftXgPercentage,*/
/*.teamRightXgPercentage {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*  height: 100%;*/
/*}*/

/*.middleSeparator {*/
/*  width: 2px;*/
/*  background-color: white;*/
/*  position: relative;*/
/*  z-index: 10;*/
/*}*/
.scoreContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.playerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* Adjust this to your liking */
  font-size: clamp(8px, 12vw, 96px);
  font-weight: bold;
  /*color: #595959;*/
}

.playerName {
  font-size: clamp(6px, 1vw, 32px);
  font-weight: bold;
  text-align: center;
  white-space: nowrap; /* Prevents breaking into two lines if possible */
}

.playerMugshot {
  /*width: 80px;*/
  /*height: 80px;*/
  border-radius: 50%;
  /*object-fit: cover;*/
  /*margin-bottom: 5px;*/
  height: clamp(8px, 15vw, 150px); /* Adjust size as needed */
  width: auto;
  margin: 50px;
}
.dualMugshots {
  display: flex;
  /*gap: 10px; !* Adjust spacing between the two images *!*/
  /*border-radius: 50%;*/
  /*height: clamp(8px, 15vw, 150px); !* Adjust size as needed *!*/
  /*width: auto;*/
  /*margin: 50px;*/
}

.vsText h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 10px;
}

.loadingBarContainer {
  margin-top: 15px;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* or set dynamically based on content */
  width: 100%;
}
/* Mobile adjustments for devices up to 768px wide */
@media (max-width: 768px) {
  /* Ensure the main container doesn't exceed the viewport width */
  .pageContainer {
    padding: 10px;
  }

  /* Score container: stack items vertically if needed */
  .scoreContainer {
    /*flex-direction: column;*/
    align-items: center;
  }

  /* Player info: use full width and reduce margins */
  .playerInfo {
    width: 100%;
    margin: 10px 0;
  }

  /* Reduce the size of player mugshots and their margins */
  .playerMugshot {
    margin: 10px auto;
    height: clamp(50px, 15vw, 100px);
  }

  /* Adjust loading bar containers if necessary */
  .loadingBarContainer {
    width: 100%;
  }
    .pageContainer {
    padding: 10px;
  }

  /* Stack filters and compare container vertically */
    .timeframeSelect,
    .compareButton {
  flex: 1 1 300px;           /* They will shrink below 300px if needed */
  max-width: 100%;           /* Do not exceed container width */
  min-width: 0;              /* Allow items to shrink */
  box-sizing: border-box;
}

  /* Make select and button span the full width (with an optional max width) */
  .timeframeSelect,
  .compareButton {
    width: 70%;
    max-width: 300px;  /* Adjust as needed */
  }
}
.compareButtonContainer {
  display: flex;
  flex-wrap: wrap;           /* Allow items to wrap if needed */
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 10px;           /* Some horizontal padding */
  box-sizing: border-box;
  overflow-x: hidden;        /* Prevent horizontal overflow */
}
/*.subtitle {*/
/*  margin-top: 20px;*/
/*  font-size: 20px;*/
/*  font-weight: bold;*/
/*  color: #333;*/
/*}*/
/*.loadingBarTitle {*/
/*  margin-bottom: 5px;*/
/*  font-weight: bold;*/
/*}*/