/*.nameBar {*/
/*    text-align: center;*/
/*    color: #595959;*/
/*    font-family: 'Rockwell', serif;*/
/*    display: inline-block;*/
/*    padding-top: 15px;*/
/*    padding-bottom: 8px;*/
/*    font-size: 56px;*/
/*}*/
.playerPageWrapper {
  transform: scale(0.9);
  transform-origin: top center;
  width: 100%;
}

.nameBarContainer {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80px;
}
.profileHeader {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    width: 100%;
    /*padding: 20px;*/
    margin-left: auto;
    margin-right: auto;
}

.mug {
    grid-row: 1 / span 2;
    width: clamp(12px, 20vw, 180px);
    /*max-width: 500px;*/
}
.profileInfo {
    grid-column: 2;
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    width: 100%;
}
.nameBar {
    grid-column: 2;
    grid-row: 1;
    text-align: center;
    color: #595959;
    font-family: 'Rockwell', serif;
    display: inline-block;
    padding-top: clamp(3px, 1vw, 15px);
    padding-bottom: clamp(3px, 1vw, 8px);
    font-size: clamp(10px, 4vw, 50px);
    /*border-bottom: 2px solid #595959;*/
}

.teamLogo {
    grid-column: 2;
    grid-row: 2;
    width: clamp(12px, 7vw, 64px);
}
.tableOutline {
    width: 85%;
    max-width: 800px;
    /*max-height: 1000px;*/
    padding: 10px;
    margin: 0 auto;
    font-size: 19.2px;
    border-radius: 35px;
    border: 3px solid #595959;
    overflow: hidden;
}

.flag {
    width: auto;
    height: clamp(4px, 1.75vw, 20px);;
    /*max-width: 125px;*/
    vertical-align: middle;
}
.divider {
    border: 0;
    height: 5px;
    background-color: #595959;
    position: relative;
    margin: 10px 0;
}

.tableContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    width: 90%;
    margin: 0 auto;
    gap: 3px;
}

.table {
    width: 90%;
    max-width:600px;
    border-collapse: collapse;
    text-align: center;
}

th, td {
    padding: 6px;
    text-align: center;
    vertical-align: middle;
    font-size: clamp(6px, 2vw, 16px);
}

th {
    background-color: #eeeeee;
    font-weight: bold;
    font-size: clamp(12px, 7vw, 56px);
}

.overallCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(18px, 6vw, 60px);
    /*max-width: 125px;*/
    /*max-height: 125px;*/
    height: clamp(18px, 6vw, 60px);
    border-radius: 50%;
    border: 8px solid #ddd;
}

.overallRating {
    font-size: clamp(8px, 4vw, 28px);
    color: #595959;
}

.analyticCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(20px, 2.5vw, 40px);
    /*max-width: 115px;*/
    /*max-height: 115px;*/
    height:  clamp(20px, 2.5vw, 40px);
    border-radius: 50%;
    border: 4px solid #ddd;
}

.analyticCircle span {
    font-size:  clamp(8px, 0.75vw, 18px);
    color: #595959;
}

.analytic {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    float: right;
}
.bioBackground {
    background-color: #eeeeee;
    align-items: center;
    border-radius: 9px;
    /*padding: 12px;*/
    margin-top: 6px;
    margin-bottom: 6px;
    width: 90%;
    max-width: 450px;
}
.bioContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 25px;
    /*gap: 4px;*/
}

.bioBox {
    /*background-color: #eeeeee;*/
    color: #595959;
    padding: 5px;
    margin-top: 3px;
    margin-right: 5px;
    border-radius: 9px;
    font-size:  clamp(12px, 3vw, 18px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    text-align: center;
}

.bioItem {
    flex: 1;
    /*margin: 0 10px;*/
    /*font-family: 'Rockwell', serif;*/
    font-size:  clamp(4px, 1.2vw, 16px);
    font-style: italic
}

.bioBox img {
    display: inline-block;
    height:  clamp(4px, 2.3vw, 25px);
    /*margin-left: 10px;*/
}
/*.loadingContainer {*/
/*    width: 60%;*/
/*    max-width: 600px;*/
/*    height: 45px; !* Adjust as needed *!*/
/*    background-color: #e0e0e0;*/
/*    border-radius: 5px;*/
/*    overflow: hidden;*/
/*    position: relative;*/
/*    margin: 5px auto;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.loadingBar {*/
/*    height: 100%;*/
/*    background-color: #595959;*/
/*    width: 0%; !* Initial width set to 0 *!*/
/*    border-radius: 5px 0 0 5px;*/
/*    transition: width 2.5s ease;*/
/*    position: absolute; !* Position the bar absolutely within the container *!*/
/*    left: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*}*/

/*.loadingText {*/
/*    width: 100%;*/
/*    text-align: center;*/
/*    color: #595959;*/
/*    font-weight: bold;*/
/*    font-size: 26px;*/
/*    font-style: italic;*/
/*    z-index: 1; !* Ensure the text stays above the loading bar *!*/
/*    padding: 5px;*/
/*}*/
.loadingContainer {
    width: 60%;
    /*max-width: 600px;*/
    height: clamp(12px, 2vw, 36px);; /* Adjust as needed */
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingBar {
    height: 100%;
    background-color: #595959;
    width: 0%; /* Initial width set to 0 */
    border-radius: 5px 0 0 5px;
    transition: width 2.5s ease;
    position: absolute; /* Position the bar absolutely within the container */
    left: 0;
    top: 0;
    bottom: 0;
}

.loadingText {
    position: absolute; /* Position the text absolutely within the container */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    color: #595959;
    font-weight: bold;
    font-size:   clamp(8px, 1.5vw, 24px);
    font-style: italic;
    z-index: 1; /* Ensure the text stays above the loading bar */
}
.overallContainer {
    width: 90%;
    max-width: 450px;
    height: clamp(18px, 2.5vw, 48px);
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overallBar {
    height: 100%;
    background-color: #595959;
    width: 0%; /* Initial width set to 0 */
    border-radius: 5px 0 0 5px;
    transition: width 2.5s ease;
    position: absolute; /* Position the bar absolutely within the container */
    left: 0;
    top: 0;
    bottom: 0;
}
.overallText {
    position: absolute;
    text-align: center;
    color: #595959;
    font-weight: bold;
    /*padding: 5px;*/
    font-size:  clamp(12px, 1.5vw, 30px);
    z-index: 1; /* Ensure the text stays above the loading bar */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.statCategory {
    width: 70%;
    max-width: 400px;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin: 10px auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align: center;
    gap: 5px;
}

.statCategory h1 {
    color: #595959;
    text-align: center;
    font-family: 'Rockwell', serif;
    font-size:  clamp(10px, 3vw, 20px);
}

.statSectionContainer {
    display: flex;
    justify-content: center;
}

.statSection {
    flex: 1;
    margin: 0 10px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*grid-template-rows: repeat(2, 1fr);*/
    width: 100%;
    align-items: baseline;
}

.analyticCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.analyticCategory h1 {
    margin-bottom: 5px;
    margin-top: 5px;
    color: #595959;
    font-weight: bold;
    text-align: center;
    font-size:  clamp(8px, 1.5vw, 16px);
    font-family: 'Rockwell', serif;
}

.analyticCategory table {
    width: 70%;
    border-collapse: collapse;
}
/*.analyticCategory tr {*/
/*    width: 100%;*/
/*    border-collapse: collapse;*/
/*    margin-top: 2px;*/
/*    margin-bottom: 2px;*/
/*}*/

.analyticCategory td {
    padding: 3px;
    color: #595959;
    text-align: left;
    font-size:  clamp(6px, 1vw, 16px);
    font-style: italic;
}
.blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px); /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.blurMessage {
    text-align: center;
    font-size: 4vw;
    color: #333;
}
.blurMessage img {
    height: 10vw;
    width: auto;
}
.glossary {
    max-width: 1500px;
    min-width: 300px;
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
    /*margin-bottom: 50px;*/
}

.glossary h2 {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 50px;
    color: #595959;
}

.glossary dl {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px 20px;
    align-items: start;
    margin: 0;
}

.glossary dt {
    font-weight: bold;
    /*width: 75px;*/
    width: clamp(75px, 20vw, 200px)
}

.glossary dd {
    margin: 0;
}
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.footer {
    background-color: #f8f8f8;
    padding: 20px;
    border-top: 1px solid #ddd;
}
.explainer {
    width: 70%;
    max-width: 1200px;
    align-items: center;
    font-style: italic;
    padding-top: 10px;
}
.snakeContainer {
    width: 70%;
    margin-top: 80px;
    margin-bottom: 80px;
    /*margin: 0 auto;*/
    text-align: center;
    /*display: flex;*/
    /*justify-content: space-between;*/
    align-items: center;
}
.tableTitle {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    color: #595959;
    font-style: italic;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    /*font-variant: small-caps;*/
}
.playerRatingsHeader {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    margin-top: 50px;
    color: #595959;
    font-style: italic;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    /*font-variant: small-caps;*/
}