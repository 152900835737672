.teamLogo {
  height: clamp(6px, 4vw, 50px);
  width: auto;
}
.nhlLogo {
    height: clamp(20px, 5.5vw, 50px);
    width: auto;
    padding: 4px;
}
.mug {
    width: auto;
    height: 40px; /* Maintain aspect ratio */
  /*vertical-align:bottom;*/
}
.teamTableHeading {
  text-align: center;
  font-size: clamp(6px, 4.75vw, 30px);
  color: #0097b3;
}
.teamRows {
    text-decoration: none;
    /*font-size: 35px;*/
    font-size: clamp(10px, 3vw, 20px);
    text-align: left;
    /*font-family: DejaVu Sans Mono, monospace;*/
    /*font-variant: small-caps;*/
    font-weight: 500;
    /*width: 50%; !* Adjust according to col-me-6 (assuming it is 50% width) *!*/
    /*max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*/
  /*vertical-align:bottom;*/
}
.mainTable{
    text-align: center; /* text-center */
    margin: 0 auto; /* mc-auto */
    padding: 0; /* p-0 */
    width: 65%; /* Adjust according to col-me-6 (assuming it is 50% width) */
    max-width: 600px; /* Adjust according to col-sma-10 (assuming it is responsive) */
    min-width: 300px;
}
.smallWidthCentered {
    width: 75%;
    margin: 0 auto; /* This centers the element */
}
/*.row {*/
/*    display: flex; !* Flexbox for layout *!*/
/*    justify-content: center; !* Center the row horizontally *!*/
/*    align-items: center; !* Center the row vertically *!*/
/*}*/

/*.centeredCol {*/
/*    text-align: center; !* Center text *!*/
/*    flex: 0 0 auto;*/
/*    width: 50%; !* Adjust this based on your desired width *!*/
/*    max-width: 83.33333%; !* Adjust this based on your desired width *!*/
/*    margin: auto; !* Center the element horizontally and vertically *!*/
/*    padding: 0; !* No padding *!*/
/*}*/

/*.cardCentered {*/
/*    padding: 1rem; !* Add padding to the card *!*/
/*    text-align: center; !* Center text inside the card *!*/
/*}*/

/*.listGroup {*/
/*    list-style-type: none; !* Remove default list styling *!*/
/*    padding: 0; !* Remove default padding *!*/
/*    margin: 0; !* Remove default margin *!*/
/*    text-align: left; !* Left align text inside the list group *!*/
/*}*/
