.dropdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.dropdownLabel {
    margin-right: 10px;
    font-size: 1.2em;
}

.dropdown {
    padding: 5px;
    font-size: 1em;
}