.pageContainer {
    text-align: center;
    align-items: center;
    /*display: flex;*/
    justify-content: center;
}

.teamLogo {
    height: clamp(12px, 10vw, 96px); /* Adjust size as needed */
    width: auto;
}
.buttonContainer {
    display: flex;
    flex-direction: row; /* Place buttons side by side */
    justify-content: center; /* Center the buttons horizontally within the container */
    align-items: center; /* Center the buttons vertically */
    gap: 30px; /* Space between buttons */
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%; /* Set the container width to 90% */
    margin: 0 auto; /* Center the container itself */
}

.button {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    text-align: center;
    background-color: #eeeeee;
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}
.button h1 {
    color: #595959;
    /*font-family: 'Rockwell', serif;*/
    text-align: center;
    font-size: 24px;
    font-style: italic;
    /*font-variant: small-caps;*/
}
.teamHeading {
    font-size: clamp(12px, 10vw, 96px);
    padding: 35px 20px;
    border-bottom: 3px solid;
    border-top: 3px solid;
    background-color: white;
    /*font-family: 'Rockwell', serif;;*/
}
.teamRecord {
    font-size: clamp(8px, 8vw, 84px);
    padding: 35px 20px;
    font-style: italic;
}
.barContainer {
    width: 80%; /* Adjust the width as needed */
    max-width: 600px; /* Limit the width to a max value */
    margin: 0 auto; /* Center the container */
    position: relative;
    height: 50px; /* Adjust the height of the bar */
    background-color: #eeeeee; /* Light background for contrast */
    border-radius: 15px;
    overflow: hidden;
}

.barBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.barFill {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform-origin: left;
    transition: width 1.5s ease; /* Add animation timing here */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-weight: bold;
    background-color: green; /* Default color */
}

.barFill.positive {
    border-radius: 0 15px 15px 0;
}

.barFill.negative {
    border-radius: 15px 0 0 15px;
}

.barLabel {
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center;
    pointer-events: none;
}
.zeroLine {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: black;
    left: 50%;
    top: 0;
}

.zero-line {
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: red;
    left: 50%;
    top: -5px;
}
.barTitle {
    font-size: clamp(4px, 1.5vw, 36px);
    padding: 10px;
    text-align: center;
    color: #595959;
}
/*.playerRatingsHeader {*/
/*    font-size: clamp(8px, 3vw, 84px);*/
/*    padding-top: 40px;*/
/*    font-style: italic;*/
/*}*/
.playerRatingsHeader {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    margin-top: 50px;
    color: #595959;
    font-style: italic;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    /*font-variant: small-caps;*/
}
/*.snakeContainer {*/
/*    width: 70%;*/
/*    margin-top: 80px;*/
/*    margin-bottom: 80px;*/
/*    !*margin: 0 auto;*!*/
/*    text-align: center;*/
/*    !*display: flex;*!*/
/*    !*justify-content: space-between;*!*/
/*    align-items: center;*/
/*}*/