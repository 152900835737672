.contentContainer {
    margin: auto;
    max-width: 2000px;
    width: 100%;
    padding-bottom: 50px; /* Adjust the value as needed for bottom space */

}

.contentHeading {
    border-bottom: 1px dotted black;
    margin: 64px 0;
    text-transform: uppercase;
}