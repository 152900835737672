.teamLogo {
    height: clamp(8px, 10vw, 75px); /* Adjust size as needed */
    width: auto;
    margin: 50px;
}
.recapContainer {
    text-align: center;
    align-items: center;
}

.scoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    margin: 20px;
}
.score {
    /*font-size: 48px;*/
    font-size: clamp(8px, 4.5vw, 48px);
    font-weight: bold;
    color: #595959;
}
.loadingBarContainer {
    width: 80%;
    max-width: 600px;
    /*margin-top: 20px;*/
    margin: 0 auto;
    display: flex;
    /*align-items: center;*/
    justify-content: center;

}

.loadingBar {
    display: flex;
    height: 50px;
    width: 100%;
    max-width: 600px;
    background-color: #595959;
    border-radius: 15px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin: 20px;
    border: 2px solid #000; /* Add border here */

}
.xgText {
    color: #eeeeee;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    z-index: 1;
    text-align: center;
    vertical-align: center;
    white-space: nowrap; /* Ensure text doesn't wrap */
    overflow: hidden; /* Prevent text overflow */
    text-overflow: ellipsis; /* Handle overflowed text with ellipsis */
    /*padding: 0 5px; !* Add some padding for better appearance *!*/
}

.teamLeftXgPercentage, .teamRightXgPercentage {
    height: 100%;
    display: flex; /* Use Flexbox for alignment */
    justify-content: center; /* Center horizontally (if needed) */
    align-items: center; /* Center vertically */
    /*border: 2px black;*/
}
.teamLeftXgPercentage {
    background-color: #595959;
    border-right: 2px solid #eeeeee;
}
.teamRightXgPercentage {
    background-color: #595959;
}

.loadingBarTitle {
    font-size: clamp(8px, 4vw, 24px);
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    color: #595959;
}
.xgText {
    color: #eeeeee;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    z-index: 1;
    text-align: center;
    vertical-align: center;
    white-space: nowrap; /* Ensure text doesn't wrap */
    overflow: hidden; /* Prevent text overflow */
    text-overflow: ellipsis; /* Handle overflowed text with ellipsis */
    padding: 0 5px; /* Add some padding for better appearance */
}
.tableContainer {
    width: 25%;
    margin-top: 20px;
    /*margin: 0 auto;*/
    text-align: center;
    /*display: flex;*/
    /*justify-content: space-between;*/
    align-items: center;
}
.snakeContainer {
    width: 70%;
    margin-top: 80px;
    margin-bottom: 80px;
    /*margin: 0 auto;*/
    text-align: center;
    /*display: flex;*/
    /*justify-content: space-between;*/
    align-items: center;
}
.tableTitle {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    color: #595959;
    font-style: italic;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    /*font-variant: small-caps;*/
}

.tableSubtitle {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    color: #595959;
    font-style: italic;
    /*font-weight: bold;*/
    /*font-size: 24px;*/
    font-size: clamp(2px, 1vw, 20px);
    /*font-variant: small-caps;*/
}

.playerRow {
    text-decoration: none;
    color: #595959;
    /*font-size: 35px;*/
    /*font-size: 12px;*/
    font-size: clamp(4px, 2vw, 12px);
    text-align: left;
    /*font-family: DejaVu Sans Mono, monospace;*/
    /*font-variant: small-caps;*/
    font-weight: 500;
    /*width: 50%; !* Adjust according to col-me-6 (assuming it is 50% width) *!*/
    /*max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*/
  /*vertical-align:bottom;*/
}

.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 8px; /* Adjust padding to decrease row height */
    border: 1px solid #ddd;
    transition: background-color 0.3s ease;
    color: #595959; /* Set text color */
}

.listItem:hover {
    background-color: #f9f9f9;
}

.playerRank {
    font-weight: bold;
    margin-right: 10px;
}

.playerName {
    flex: 1;
    margin-right: 5px;
    text-align: left; /* Ensure text aligns left */
    /*font-size: 16px;*/
    font-size: clamp(6px, 2.5vw, 14px)
}

.playerLink {
    text-decoration: none; /* Remove underline */
    color: #595959; /* Set text color */
}

.playerMug {
    flex-shrink: 0;
    /*margin-right: 10px;*/
}

.mug {
    width: auto;
    height: clamp(6px, 4vw, 50px);
    border-radius: 50%;
}
.matchupMug {
    width: auto;
    height: clamp(6px, 9vw, 100px);
    border-radius: 50%;
}

.expectedGoals {
    flex-shrink: 0;
    font-size: clamp(3px, 2vw, 12px);
    /*font-weight: bold;*/
}
.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.divider {
    width: 80%; /* Set the width to ensure it spans the container */
    border: 0;
    height: 3px;
    background-color: #595959;
    margin: 20px 0;
    align-self: center; /* Center the divider within the flex container */
}
.teamLogo {
    /*flex-shrink: 0;*/
    /*margin-right: 10px;*/
}
.logo {
    width: auto;
    height: clamp(6px, 4vw, 30px);
    /*border-radius: 50%;*/
}
.tablesContainer {
    width: 95%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between; /* Space the tables as far apart as possible */
    align-items: center; /* Center the tables vertically within the container */
}

.tableWrapper {
    width: 100%; /* Ensure the table wrapper takes full width */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    margin-bottom: 20px; /* Add some space below the table */
}

.xgTable {
    border-collapse: collapse;
    margin: 0 auto; /* Center the table */
    width: 95%;
}
.xgTable th, .xgTable td {
    border: 1px solid White; /* Add a thin border to each cell */
    padding: 4px 8px; /* Reduce padding to decrease row height */
    text-align: center;
    line-height: 1.2; /* Set line-height to adjust row height */
    font-size: clamp(3px, 2vw, 12px);
}
.header {
    background-color: White;
    font-weight: bold;
    /*font-size: 16px;*/
    font-size: clamp(6px, 2.5vw, 14px);
    color: #595959;
    text-align: center;
}
.footer {
    background-color: #f8f8f8;
    padding: 20px;
    border-top: 1px solid #ddd;
}

.glossary {
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

.glossary h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #595959;
}

.glossary dl {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px 20px;
    align-items: start;
    margin: 0;
}

.glossary dt {
    font-weight: bold;
}

.glossary dd {
    margin: 0;
}
/*.rinkContainer {*/
/*    max-width: 600px; !* Maximum width for the image *!*/
/*    margin: auto; !* Center the container *!*/
/*    position: relative;*/
/*}*/
.rinkContainer {
    background-color: transparent; /* Ensure the container is transparent */
    position: relative;
    /*width: 600px; !* Explicit width *!*/
    /*height: 360px; !* Explicit height *!*/
    width: 900px; /* Increase the container size */
    height: 540px; /* Maintain the aspect ratio */
    margin: auto; /* Center within flex container */
}
.rink {
    width: 100%;
    height: auto;
    display: block;
    filter: saturate(25%) brightness(110%);


}
.winnerShot, .loserShot {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid;
    z-index: 10;
    pointer-events: auto; /* Ensure the shot is interactable */
    /*background-color: gray;*/
}
.winnerShot:hover, .loserShot:hover, .goal:hover {
    outline: 3px solid black;  /* Add a black outline when hovered */
}
.winnerGoal, .loserGoal {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 4.5px solid limegreen;
    z-index: 10;
    pointer-events: auto; /* Ensure the shot is interactable */
    background-color: red;
}

.tooltip {
    position: absolute; /* Fixed positioning to always be on top */
    /*position: absolute;*/
    bottom: 100%;  /* Position above the shot */
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;  /* Adjust distance from the shot */
    background-color: #eeeeee;
    color: #595959;
    padding: 5px;
    border-radius: 3px;
    font-size: 18px;
    white-space: nowrap;
    visibility: hidden;
    z-index: 10000;
    text-align: center;
    border: 3px solid black;
}
.tooltip img {
    display: block;
    margin: 10px auto;  /* Center the image horizontally and add some margin */
}

/* Show tooltip on hover */
.winnerShot:hover .tooltip, .loserShot:hover .tooltip, .winnerGoal:hover .tooltip, .loserGoal:hover .tooltip {
    visibility: visible;
    z-index: 10000; /* Ensure tooltip is above all shots */
}
.dropdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.dropdownLabel {
    margin-right: 10px;
    font-size: 1.2em;
}

.dropdown {
    padding: 5px;
    font-size: 1em;
}
.muffinOfTheGameContainer {
  text-align: center;
  margin-bottom: 20px;
    padding: 10px;
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Space below the heading */
}

.stickImage {
  width: clamp(20px, 3vw, 125px); /* Adjust the size as needed */
  height: auto;
  margin: 0 10px; /* Space between the images and the heading */
}

.muffinOfTheGameHeading {
  font-size: clamp(8px, 4.5vw, 48px);
  margin-bottom: 10px;
    font-style: italic;
    color: #595959;
}

.muffinOfTheGameContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.muffinGif {
  width: clamp(50px, 7vw, 200px); /* Adjust the size as needed */
  height: auto;
  margin: 20px; /* Space between the GIF and player info */
}

.muffinOfTheGameInfo {
  display: flex;
  align-items: center;
}

.muffinOfTheGameInfo img.mug {
  width: clamp(50px, 8vw, 200px); /* Adjust the size of the player's mugshot */
  height: auto;
  margin-bottom: 10px;
}

.muffinPlayerDetails {
  display: flex;
  align-items: center;
}

.muffinOfTheGameName {
  /*font-weight: bold;*/
  font-weight: bold;
    font-size: clamp(8px, 4vw, 64px);
    padding-left: 30px;
    padding-right: 30px;
    /*color: #595959;*/
    color: var(--primary-color);
  margin-bottom: 5px;
  text-decoration: none;
}

.muffinOfTheGameName:hover {
  text-decoration: underline;
}

.gameScoreBox {
  display: inline-block;
    font-size: clamp(8px, 3vw, 64px);
  padding: 10px 10px;
  border-radius: 4px;
  color: #595959; /* Ensure text is readable on colored backgrounds */
  font-weight: bold;
  /* Optional: Add a border or shadow */
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stolenGameContainer {
  text-align: center;
  margin: 20px auto;
  padding: 10px;
}

.stolenImage {
  width: clamp(150px, 15vw, 500px);
  height: auto;
}

.stolenGameText {
  font-size: clamp(8px, 4vw, 24px);
  margin-top: 10px;
  color: #333;
  line-height: 1.5;
}
.stolenImageContainer {
  position: relative;
  display: inline-block;
}
.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 8px 10px;
  position: absolute;
  z-index: 1;
  bottom: 110%; /* Positions tooltip above the image */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.stolenImageContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.tooltipText::after {
  content: '';
  position: absolute;
  top: 100%; /* Arrow points downwards */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

.playerLink:hover {
  text-decoration: underline;
}
.deadOrAliveContainer {
  position: relative;
  display: inline-block; /* Keeps the container inline with content */
  width: 100%; /* Ensures the container takes up the full width available */
  max-width: 800px; /* Optional: Set a max width for the container */
}

.deadOrAliveImg {
  display: block;
  width: 30%; /* Adjust to make the image smaller */
  height: auto;
  margin: 0 auto; /* Center the image */
}

.deadOrAliveMug {
  position: absolute;
  top: 50%;    /* Adjust to position the mug vertically */
  left: 50%;   /* Adjust to position the mug horizontally */
  transform: translate(-47.5%, -45%); /* Centers the mug over the image */
  width: 20%;  /* Adjust the mug size relative to the background image */
  height: auto;
  z-index: 1;
}