.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: #0097b3;
  background-color: #ffffff;
}

.pageTitle {
  font-size: clamp(24px, 4vw, 48px);
  font-weight: bold;
  margin-bottom: 30px;
  color: #0097b3;
}

.playerSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20px;
}

.statSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.statSelectorWrapper label {
  font-weight: bold;
}

.statSelector {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #0097b3;
}

/* Responsive layout for mobile */
@media (max-width: 768px) {
  .playerSelectors {
    flex-direction: column;
    gap: 15px;
  }

  .generateButton {
    width: 80%;
  }
}
.mug {
  /*width: clamp(50px, 6vw, 200px);*/
  width: 70px;
  height: auto;
  border-radius: 90%;
}


/* Container for the entire page */
.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Minimum 20px between rows */
  padding: 20px;
}

/* Page title styling */
.pageTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Segmented control for the top-level compare toggle */
.compareToggle {
  display: flex;
  gap: 0; /* no gap so segments touch */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  max-width: 400px;
}
.compareToggle label {
  flex: 1;
  padding: 10px 20px;
  text-align: center;
  background-color: #f0f0f0;
  cursor: pointer;
  user-select: none;
  border-right: 1px solid #ccc;
  transition: background-color 0.3s;
}
.compareToggle label:last-child {
  border-right: none;
}
.compareToggle input[type="radio"] {
  display: none;
}
.compareToggle input[type="radio"]:checked + label {
  background-color: #0097b3;
  color: #fff;
}

/* Each individual filter group */
.filterGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 150px;
}

/* Styling for select elements and inputs */
.statSelector, .searchInput, .filterGroup select, .filterGroup input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Optional Filters Container */
.optionalFilters {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  max-width: 800px;
}

.optionalHeader {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #666;
}

/* Filter Bar - for grouping the optional filters in one row */
.filterBar {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
}

/* Player/Team selectors - arranged side by side */
.playerSelectors {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
}

/* Additional Filters row */
.additionalFilters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  /*max-width: 800px;*/
}


/* Generate Chart Button */
.generateButton {
  margin: 25px;
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #0097b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.generateButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.generateButton:hover {
  background-color: #007a8a;
}

.playerDropdown {
  min-width: 250px;
}
.statSelectorDropdown {
  min-width: 300px;
}
.groupByDropdown {
  min-width: 175px;
}
.positionNationalityDropdown {
  min-width: 175px;
}
.filterLabel {
  display: block;
  margin-bottom: 5px;
  /*font-weight: bold;*/
  font-size: 1rem;
  color: #0097b3;
}
.compositeControl {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.numberInput {
  width: 80px;          /* Make it narrower */
  text-align: center;   /* Center the text inside the input */
  height: 37px;         /* Match the height of your other selectors */
  padding: 0 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.sectionDivider {
  border: none;
  border-bottom: 2px solid #595959; /* or your preferred color */
  width: 100%;
  margin: 20px 0;
}
.numberInputWrapper {
  width: 150px; /* same as other filterGroup widths */
  display: flex;
  justify-content: center;
}
.chartTitle {
  margin-top: 30px;
  font-weight: bold;
  color: #0097b3;
}
.componentContainer {
    text-align: center;
    align-items: center;
    /*display: flex;*/
    justify-content: center;
    width: 75%;
    /*margin-bottom: 100px;*/
      margin: 0 auto 100px auto; /* top: 0, right: auto, bottom: 100px, left: auto */

}

.statDropdownContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
    margin-top: 50px;
}

.statDropdownLabel {
  margin-right: 0.5rem;
  font-weight: bold;
}

.statDropdown {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.snakeChartTitle {
    /*text-decoration: underline;*/
    margin-bottom: 10px; /* Reduce space between title and table */
    margin-top: 50px;
    color: #595959;
    font-style: italic;
    font-weight: bold;
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 24px);
    /*font-variant: small-caps;*/
}
.additionalFilters {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*gap: 20px;*/
  /*margin: 20px 0;*/
}
