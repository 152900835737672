.graphContainer {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 30px;
}

.playerRow {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.playerName {
  color: var(--primary-color);
  text-decoration: none;
  width: clamp(65px, 12vw, 300px);
  font-size: clamp(10px, 1.2vw, 30px);
  text-align: left;
  font-weight: bold;
  margin-right: 10px;
  /*font-style: italic;*/
}

.playerName:hover {
  color: var(--hover-color);
}

.ratingBarContainer {
    flex-grow: 1;
    height: clamp(15px, 3.25vw, 30px); /* Adjust height of the bar */
    background-color: #eeeeee;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
}

.ratingBarFill {
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    padding-right: 10px; /* Add padding to prevent label cut-off */
    transition: width 1s ease-in-out;
}
.ratingBarFill:hover {
  background-color: var(--hover-color);
}

.ratingBarLabel {
    z-index: 2;
    color: white;
}

.mug {
    width: clamp(20px, 2.25vw, 75px); /* Adjust size of player images */
    height: auto;
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover;
}