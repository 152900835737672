/*!*.navBar {*!*/
/*!*    align-items: center;*!*/
/*!*    display: flex;*!*/
/*!*    justify-content: center;*!*/
/*!*    padding: 25px 25px;*!*/
/*!*}*!*/
/*.navBar {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    padding: 20px;*/
/*    !*background-color: #333;*!*/
/*    position: relative;*/
/*}*/

/*.logoContainer {*/
/*    margin-bottom: 10px; !* Space between the logo and buttons *!*/
/*}*/

/*.siteHeading {*/
/*    border-bottom: 2px solid steelblue;*/
/*    border-top: 2px solid steelblue;*/
/*    color: steelblue;*/
/*    display: inline-block;*/
/*    font-size: 100px;*/
/*    !*font-family: 'Rockwell', serif;*!*/
/*    !*font-variant: small-caps;*!*/
/*    text-transform: lowercase;*/
/*    padding: 4px;*/
/*}*/
/*.muffinLogo {*/
/*    width: 200px;*/
/*    height: auto;*/
/*}*/

/*.randomButton {*/
/*    float: right;*/
/*}*/
/*.navButtonsContainer {*/
/*    display: flex;*/
/*    width: 50%;*/
/*    justify-content: space-evenly;*/
/*    gap: 10px;*/
/*    position: relative;*/
/*}*/

/*.navButton {*/
/*    background-color: #0097b3;*/
/*    color: white;*/
/*    padding: 10px 15px;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*}*/

/*.navButton:hover {*/
/*    background-color: #a0732c;*/
/*}*/

/*.dropdownContainer {*/
/*    position: relative;*/
/*}*/

/*.overlayDropdown {*/
/*    position: absolute;*/
/*    top: 100%; !* Dropdown appears below the button *!*/
/*    left: 0;*/
/*    background-color: #eeeeee;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);*/
/*    z-index: 10; !* Ensure the dropdown appears on top of other elements *!*/
/*    width: 250px; !* Optional: Set a width for the dropdown *!*/
/*}*/

/*.dropdownMenu {*/
/*    padding: 10px;*/
/*    margin: 0;*/
/*    list-style-type: none;*/
/*    max-height: 300px;  !* Optional: Add a height limit *!*/
/*    overflow-y: auto;   !* Add scrollbar if necessary *!*/
/*}*/

/*.teamRows {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding: 8px;*/
/*    color: white;*/
/*    text-decoration: none;*/
/*}*/

/*.teamRows:hover {*/
/*    background-color: #a0732c;*/
/*}*/

/*.teamLogo {*/
/*    width: 20px;*/
/*    height: auto;*/
/*    margin-right: 10px;*/
/*}*/
/*.teamRows {*/
/*    text-decoration: none;*/
/*    !*font-size: 35px;*!*/
/*    font-size: clamp(6px, 1vw, 24px);*/
/*    text-align: left;*/
/*    !*font-family: DejaVu Sans Mono, monospace;*!*/
/*    !*font-variant: small-caps;*!*/
/*    font-weight: 500;*/
/*    !*width: 50%; !* Adjust according to col-me-6 (assuming it is 50% width) *!*!*/
/*    !*max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*!*/
/*  !*vertical-align:bottom;*!*/
/*}*/
/*.teamRows span {*/
/*    color: #595959;*/
/*}*/
.navBar {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    padding: clamp(15px, 3.25vw, 30px);
    position: relative;
}

.logoContainer {
    margin-bottom: 10px; /* Space between the logo and buttons */
}

.muffinLogo {
    width: 200px;
    height: auto;
}

/* Navigation Buttons */
.navButtonsContainer {
    display: flex;
    width: 80%;
    /*justify-content: space-evenly;*/
    justify-content: center;
    gap: 5px;
    position: relative;
}

.navButton {
    background-color: #0097b3;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /*font-size: 16px;*/
    font-size: clamp(8px, 3vw, 16px);
}

.dropdownArrowButton {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #0097b3;
  color: white;
  font-size: clamp(8px, 3vw, 16px);
  cursor: pointer;
  display: inline-block;
  border-left: 1px solid white;
}

.dropdownArrowButton:hover {
  background-color: #a0732c;
}

.navButton:hover {
    background-color: #a0732c;
}

/* Dropdown Menu */
.dropdownContainer {
    position: relative;
}

/*.overlayDropdown {*/
/*    position: absolute;*/
/*    top: 100%; !* Dropdown appears below the button *!*/
/*    left: 0;*/
/*    background-color: #eeeeee;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);*/
/*    z-index: 10; !* Ensure the dropdown appears on top of other elements *!*/
/*    width: 250px; !* Optional: Set a width for the dropdown *!*/
/*}*/
.overlayDropdown {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 225px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

/*.dropdownMenu {*/
/*    padding: 10px;*/
/*    margin: 0;*/
/*    list-style-type: none;*/
/*    max-height: 300px;  !* Optional: Add a height limit *!*/
/*    overflow-y: auto;   !* Add scrollbar if necessary *!*/
/*}*/
.dropdownMenu {
  list-style: none;
  padding: 0;
  margin: 0;
    background-color: #eeeeee;
}

/* Team List Items */
.teamRows {
    padding: 10px;
    cursor: pointer;
    width: 350px;
}

/*.teamRows:hover {*/
/*  background-color: #f1f1f1;*/
/*}*/

.teamLink {
    display: flex;
    align-items: center;
    padding: 8px;
    color: #595959;
    text-decoration: none;
    font-size: clamp(12px, 1vw, 16px);
    text-align: left;
    font-weight: 500;
}

.teamLink:hover {
    background-color: #a0732c;
    color: white;
}

.teamLogo {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.teamName {
    color: #595959;
}
@media (max-width: 768px) {
  .navButtonsContainer {
      /* Allow buttons to wrap when there's not enough horizontal space */
      flex-wrap: wrap;
      width: 100%;  /* Use full width on mobile */
      justify-content: center;
  }
  .navButton,
  .dropdownArrowButton {
      /* Reduce padding and font size for smaller screens */
      font-size: clamp(8px, 3vw, 14px);
      padding: 8px 10px;
  }
  .muffinLogo {
      /* Reduce logo size on mobile */
      width: 150px;
  }
}
/* Ensure the compare container itself is constrained */