/*.pageContainer {*/
/*      display: flex;             !* Enables flexbox *!*/
/*  justify-content: center;    !* Centers horizontally *!*/
/*  align-items: center;        !* Centers vertically *!*/
/*  width: 100%;                !* Full page width *!*/
/*  !*height: 100vh;              !* Full viewport height *!*!*/
/*  padding: 20px;              !* Optional: add some padding *!*/
/*  box-sizing: border-box;*/
/*}*/
/*!* Table Styles *!*/
/*.topPerformersTable {*/
/*  width: 60%;*/
/*  border-collapse: collapse;*/
/*}*/

/*!*.topPerformersTable th,*!*/
/*.topPerformersTable td {*/
/*  padding: 10px 15px;*/
/*  border-bottom: 1px solid #ddd;*/
/*  text-align: center;*/
/*  font-size: clamp(8px, 2vw, 64px);*/
/*}*/

/*!* Header Styles *!*/
/*.topPerformersTable th {*/
/*  background-color: #eeeeee;*/
/*  font-size: clamp(8px, 1.2vw, 64px);*/
/*  !*font-weight: bold;*!*/
/*  text-align: center;*/
/*}*/

/*!* Player Cell Styling *!*/
/*.playerLink {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  text-decoration: none;*/
/*  color: inherit;*/
/*}*/

/*.playerImage {*/
/*  width: clamp(50px, 6vw, 200px);*/
/*  height: auto;*/
/*  border-radius: 50%;*/
/*  margin-right: 10px;*/
/*}*/

/*.teamLogo {*/
/*  width: clamp(50px, 1vw, 150px);*/
/*  height: auto;*/
/*}*/

/*!* Rating Cell Styling *!*/
/*.ratingCell {*/
/*  text-align: center;*/
/*  font-weight: bold;*/
/*  color: white;*/
/*  padding: 10px;*/
/*  border-radius: 5px;*/
/*  min-width: 60px;*/
/*}*/

/*!* Optional for responsive layout: ensure table remains within container *!*/
/*!*.topPerformersTable {*!*/
/*!*  width: 100%;*!*/
/*!*  max-width: 100%;*!*/
/*!*  table-layout: auto;*!*/
/*!*}*!*/

/*.vsCell {*/
/*  padding: 10px 15px;         !* Optional: padding for the cell *!*/
/*}*/

/*!* Flexbox container for the two links *!*/
/*.teamsContainer {*/
/*  display: flex;              !* Flexbox ensures links are aligned horizontally *!*/
/*  align-items: center;        !* Vertically centers the content *!*/
/*  gap: 10px;                  !* Adds spacing between the two links *!*/
/*}*/

/*!* Individual Link container *!*/
/*.teamContainer {*/
/*  display: flex;              !* Flexbox to align the image and text in each Link *!*/
/*  align-items: center;        !* Aligns image and text within the link *!*/
/*  gap: 5px;                   !* Adds some spacing between the logo and the text *!*/
/*}*/
/*!* Dash between the scores *!*/
/*.dash {*/
/*  margin: 0 5px;             !* Space on both sides of the dash *!*/
/*  font-weight: bold;*/
/*}*/
/* Container for the table and title */

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
}
.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Title and Subtitle Styling */
.tableTitle, .tableSubtitle {
  text-align: center;
  margin-bottom: 10px;
}

.tableTitle {
  font-size: clamp(24px, 3vw, 48px); /* Responsive font size */
  font-weight: bold;
  padding-bottom: 50px;
  padding-top: 50px;
  color: #0097b3;
}

.tableSubtitle {
  font-size: clamp(14px, 1.5vw, 20px); /* Responsive subtitle size */
  color: #777;
  margin-bottom: 20px;
}

/* Table Styles */
.topPerformersTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #0097b3;
  padding: 130px;
  /*table-layout: fixed; !* Enforce fixed column widths *!*/
}

.topPerformersTable th,
.topPerformersTable td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center; /* Default center alignment */
  vertical-align: middle;
  font-size: clamp(8px, 1vw, 40px);
  overflow: hidden; /* Prevent overflow from affecting layout */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent text wrapping */
}

.topPerformersTable tr {
  height: 10px;
}

.topPerformersTable tr:last-child td {
  border-bottom: none;
}

/* Column Widths */
/*.indexCell,*/
/*.gameCell,*/
/*.ratingCell {*/
/*  !*width: auto;*!*/
/*  text-align: center;*/
/*}*/

/*.indexCell {*/
/*  width: 20px;*/
/*}*/

/*.ratingCell {*/
/*  width: clamp(5px, 6vw, 20px);*/
/*  font-weight: bold;*/
/*  color: #595959;*/
/*  padding: 10px;*/
/*  border-radius: 5px;*/
/*  background-color: #4CAF50; !* Your background color *!*/
/*}*/

/*.gameCell {*/
/*  width: clamp(80px, 7vw, 180px);*/
/*}*/

/*.playerCell {*/
/*  width: clamp(150px, 25vw, 600px);*/
/*  text-align: left !important;*/
/*}*/
/* Column Widths */
.indexCell {
  width: 50px; /* Explicit width for index column */
}

.playerCell {
  width: 250px; /* Explicit width for player column */
  text-align: left !important; /* Left-align text in player column */
}

.gameCell {
  width: 180px; /* Explicit width for game column */
}

.ratingCell {
  width: 80px; /* Explicit width for rating column */
  font-weight: bold;
  color: white;
  padding: 10px;
  border-radius: 5px;
  background-color: #0097b3; /* Background color for rating */
}

/* Player Name Styling */
.playerName {
  font-size: clamp(5px, 1.5vw, 24px);
  font-weight: bold;
  text-align: left;
  padding: 0 5px;
  color: var(--primary-color);
  margin-bottom: 5px;
  text-decoration: none;
}

/* Player Link and Image Styling */
.playerLink {
  text-decoration: none;
  color: inherit;
}

.mug {
  width: clamp(25px, 2vw, 200px);
  height: auto;
  border-radius: 50%;
  margin-right: 5px;
}

/* Mini team logos in-line */
.teamLogoMini {
  width: clamp(10px, 3vw, 100px);
  height: auto;
  margin: 0 5px;
  vertical-align: middle;
}