.pageContainer {
    text-align: center;
    align-items: center;
    /*display: flex;*/
    justify-content: center;
}
.pageHeading {
    font-size: clamp(12px, 10vw, 96px);
    padding: 35px 20px;
    border-bottom: 3px solid;
    border-top: 3px solid;
    background-color: white;
    color: #0097b3;
    /*font-family: 'Rockwell', serif;;*/
}
/*.statCategory {*/
/*    font-size: clamp(8px, 3vw, 84px);*/
/*    padding-top: 40px;*/
/*    font-style: italic;*/
/*}*/
.statCategory {
    /*text-decoration: underline;*/
    padding-top: 40px;
    margin-bottom: 10px; /* Reduce space between title and table */
    color: #595959;
    font-style: italic;
    /*font-weight: bold;*/
    /*font-size: 24px;*/
    font-size: clamp(8px, 4vw, 56px);
    /*font-variant: small-caps;*/
}