/*.teamLogo {*/
/*  height: 30px;*/
/*  width: auto;*/
/*}*/
/*.mug {*/
/*    width: auto;*/
/*    height: 80px;*/
/*}*/
/*.teamTableHeading {*/
/*  text-align: center;*/
/*  font-size: 30px;*/
/*  color: darkslateblue;*/
/*}*/
/*.teamRows {*/
/*    text-decoration: none;*/
/*    font-size: 36px;*/
/*    color: #595959;*/
/*    text-align: left;*/
/*    !*height: 80px;*!*/
/*    font-weight: 500;*/
/*}*/
/*.mainTable{*/
/*    text-align: center; !* text-center *!*/
/*    margin: 0 auto; !* mc-auto *!*/
/*    padding: 0; !* p-0 *!*/
/*    width: 65%; !* Adjust according to col-me-6 (assuming it is 50% width) *!*/
/*    max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*/
/*}*/
/*.smallWidthCentered {*/
/*    width: 75%;*/
/*    margin: 0 auto; !* This centers the element *!*/
/*}*/
/* Define styles for the list items */
.listGroupItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Adjust as necessary */
    /*border-bottom: 1px solid #ddd; !* Add a border to distinguish between items *!*/
    cursor: pointer; /* list-group-item-action equivalent */
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; /* list-group-item-action transition */
}

.listGroupItem:hover, .listGroupItem:focus {
    background-color: #f8f9fa; /* list-group-item-action hover effect */
}

/* Define styles for the text content */
.playerName {
    margin-right: 20px; /* Adjust spacing as necessary */
}

/* Existing styles */
.teamLogo {
    height: clamp(12px, 10vw, 75px);
    width: auto;
}

.mug {
    width: auto;
    height: clamp(12px, 6vw, 75px);
}

.teamTableHeading {
    text-align: center;
    font-size: clamp(8px, 5vw, 30px);
    color: #0097b3;
    text-decoration: none;
}

.noDecorationLink {
    color: #0097b3;
    text-decoration: none;
}

.teamRows {
    text-decoration: none;
    font-size: clamp(8px, 2vw, 36px);
    color: #595959;
    text-align: left;
    font-weight: 500;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    margin-bottom: 5px; /* Ensures borders don't stack */
    border-radius: 3px;
}

.mainTable {
    text-align: center; /* text-center */
    margin: 0 auto; /* mc-auto */
    padding: 0; /* p-0 */
    width: 65%; /* Adjust according to col-me-6 (assuming it is 50% width) */
    max-width: 600px; /* Adjust according to col-sma-10 (assuming it is responsive) */
}

.smallWidthCentered {
    width: 75%;
    margin: 0 auto; /* This centers the element */
}
.overallCircle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white; /* Set to white or transparent */
    color: #595959; /* Text color */
    font-size:  clamp(8px, 1.75vw, 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 10px;
    flex-shrink: 0;
    border: 3px solid gray; /* Default border color, overridden by inline style */
}
.playerName {
    flex: 1; /* Allow name to take up remaining space */
    /* Add any other necessary styles */
}