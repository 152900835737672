.teamLogo {
  height: clamp(6px, 4vw, 40px);
  width: auto;
}
.teamTableHeading {
  text-align: center;
  font-size: clamp(6px, 4.75vw, 24px);
  color: #595959;
}
.gameRows {
    text-decoration: none;
    /*font-size: 35px;*/
    font-size: clamp(6px, 3vw, 20px);
    text-align: left;
    /*font-family: DejaVu Sans Mono, monospace;*/
    /*font-variant: small-caps;*/
    font-weight: 500;
    /*width: 50%; !* Adjust according to col-me-6 (assuming it is 50% width) *!*/
    /*max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*/
  /*vertical-align:bottom;*/
}
.mainTable{
    text-align: center; /* text-center */
    margin: 0 auto; /* mc-auto */
    padding: 0; /* p-0 */
    width: 65%; /* Adjust according to col-me-6 (assuming it is 50% width) */
    max-width: 600px;
    /*max-width: 100%; !* Adjust according to col-sma-10 (assuming it is responsive) *!*/
}
.gameRow {
    /* Styles from list-group-item */
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);

    /* Styles from list-group-item-action */
    width: 100%;
    height: 55px;
    color: #495057;
    text-align: inherit;
    cursor: pointer;

    /* Styles from d-flex */
    display: flex;
    align-items: center; /* Center items vertically */

    /* Styles from justify-content-between */
    justify-content: space-between;
}

